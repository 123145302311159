import Image from "next/image";
import Link from "next/link";
import newsStacksIcon from "@public/news-stack-icon.png";
import Bluetick from "@public/blue-tick.svg";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./trending-news-stacks-arrow";
import { useState } from "react";
import { useMemo } from "react";
import { Trans } from "@lingui/macro";
import { ExploreTopicSummaryFragment } from "@graphql/generated/graphql";
import Label from "@components/shared/label";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

interface ITrendingNewsStacksProps {
  newsStacks: ExploreTopicSummaryFragment[];
  isSearchNewsStacks: boolean;
}

interface ITrendingNewsStacksCardProps {
  newsStack: ExploreTopicSummaryFragment;
  itemId: any;
}

const TrendingNewsStacks: React.FunctionComponent<ITrendingNewsStacksProps> = ({
  newsStacks,
  isSearchNewsStacks,
}) => {
  const [isVisible, setIsVisible] = useState({
    firstItemVisible: true,
    lastItemVisible: false,
  });

  const ScrollMenuMemo = useMemo(
    () => (
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        scrollContainerClassName={"trending-news-stacks -order-1 pb-4"}
        wrapperClassName={"flex-wrap justify-end"}
        itemClassName={"flex"}
        onUpdate={({ isFirstItemVisible, isLastItemVisible }) => {
          setIsVisible({
            firstItemVisible: isFirstItemVisible,
            lastItemVisible: isLastItemVisible,
          });
        }}
      >
        {[null, ...newsStacks].map((category) =>
          category === null ? (
            isSearchNewsStacks ? null : (
              <WebSectionPlaceholder key={0} itemId={0} />
            )
          ) : (
            <TrendingNewsStacksCard
              itemId={category.id}
              key={category.id}
              newsStack={category}
            />
          )
        )}
      </ScrollMenu>
    ),
    [newsStacks]
  );

  return (
    <div>
      {isSearchNewsStacks ? null : <MobileSectionPlaceholder />}
      <div className="relative">
        <div className="">{ScrollMenuMemo}</div>
        <div
          className={`absolute inset-y-[-1px] left-[-1px] bottom-14 z-10 hidden w-2 bg-gradient-to-r to-white transition-opacity duration-300 md:block ${
            !isVisible.firstItemVisible ? "opacity-100" : "opacity-0"
          }`}
          style={{
            background:
              "linear-gradient(90deg, #FFFFFF 15%, rgba(255, 255, 255, 0) 100%)",
          }}
        ></div>
        <div
          className={`absolute inset-y-[-1px] right-[-1px] bottom-14 z-10 hidden w-2 bg-gradient-to-r to-white transition-opacity duration-300 md:block ${
            !isVisible.lastItemVisible ? "opacity-100" : "opacity-0"
          }`}
          style={{
            background:
              "linear-gradient(270deg, #FFFFFF 15%, rgba(255, 255, 255, 0) 100%)",
          }}
        ></div>
      </div>
    </div>
  );
};

const EmptyCard: React.FunctionComponent<{ id: any }> = ({ children }) => {
  return (
    <div
      className="w-72 shrink-0 rounded-lg bg-off-white"
      id="trending-news-stack-cards"
    >
      {children}
    </div>
  );
};

const TrendingNewsStacksCard: React.FunctionComponent<
  ITrendingNewsStacksCardProps
> = (
  {
    newsStack: {
      key,
      hot,
      updated,
      exclusive,
      imageUrl,
      exploreName,
      description,
      slug,
      author,
    },
  },
  itemId
) => {
  return (
    <EmptyCard id={itemId}>
      <Link href={`/news-stack/${slug}`} passHref>
        <a>
          <div className="flex flex-col rounded-lg bg-off-white">
            <div className="relative aspect-[1.15] w-full">
              <Image
                className="rounded-t-lg"
                src={imageUrl}
                alt={key}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="px-6 pb-6">
              <div className="mt-6">
                {exclusive ? (
                  <Label labelName="exclusive" />
                ) : updated ? (
                  <Label labelName="updated" />
                ) : (
                  hot && <Label labelName="hot" />
                )}
              </div>
              <div className="mt-2 font-poppins text-xl font-bold text-text-darkest hover:text-text-dark">
                {exploreName}
              </div>
              {author && (
                <div className="mt-2">
                  <p className="inline-flex font-sen font-bold text-text-medium">
                    {author.name}
                    {author.verified && (
                      <div className="relative ml-1 mt-1 inline-flex h-4 w-4">
                        <Image
                          src={Bluetick}
                          alt="blue-tick"
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    )}
                  </p>
                </div>
              )}
              <div className="mt-2 font-sen text-text-medium">
                {description}
              </div>
            </div>
          </div>
        </a>
      </Link>
    </EmptyCard>
  );
};

const WebSectionPlaceholder: React.FunctionComponent<{ itemId: any }> = ({
  itemId,
}) => {
  return (
    <div className="hidden lg:flex">
      <EmptyCard id={itemId}>
        <div className="flex h-full flex-col items-center justify-center rounded-lg bg-off-white p-6">
          <div className="relative h-16 w-16">
            <Image
              src={newsStacksIcon}
              alt={"News Stacks"}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <p className="font-poppins text-xl font-bold text-text-darkest">
            <Trans>Latest News Stacks</Trans>
          </p>
          <p className="mt-2 text-center font-poppins text-xs text-text-dark">
            <Trans>
              A curation of articles and videos stacked for you to give you
              insight on a big topic
            </Trans>
          </p>
          <Link href="/news-stack">
            <a className="mt-4 inline-flex">
              <div className="flex items-center rounded-xl bg-primary py-1 pl-2">
                <p className="text-center font-sen text-sm text-white">
                  <Trans>More News Stacks</Trans>
                </p>
                <ChevronRightIcon
                  className="h-6 w-6 text-secondary"
                  aria-hidden="true"
                />
              </div>
            </a>
          </Link>
        </div>
      </EmptyCard>
    </div>
  );
};

const MobileSectionPlaceholder: React.FunctionComponent = () => {
  return (
    <div className="mb-4 block rounded-lg bg-off-white p-4 lg:hidden">
      <div className="relative h-6 w-6">
        <Image
          src={newsStacksIcon}
          alt={"News Stacks"}
          layout="fill"
          objectFit="cover"
        />
      </div>
      <p className="font-poppins text-xl font-bold text-text-darkest">
        <Trans>Latest News Stacks</Trans>
      </p>
      <p className="font-xs font-sen text-text-dark">
        <Trans>
          A curation of articles and videos stacked for you to give you insight
          on a big topic
        </Trans>
      </p>
      <Link href="/news-stack">
        <a className="mt-2 inline-flex">
          <div className="flex items-center rounded-xl bg-primary py-1 pl-2">
            <p className="text-center font-sen text-sm text-white">
              <Trans>More News Stacks</Trans>
            </p>
            <ChevronRightIcon
              className="h-6 w-6 text-secondary"
              aria-hidden="true"
            />
          </div>
        </a>
      </Link>
    </div>
  );
};

export default TrendingNewsStacks;
