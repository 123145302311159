import HomePage from "@components/landing-page";
import { HomePageQuery } from "@graphql/generated/graphql";
import { ssrHome } from "@graphql/generated/page";
import { loadTranslation } from "@lib/utils";
import { GetStaticProps } from "next";
import { useScrollRestoration } from "@lib/useScrollRestoration";
import { useRouter } from "next/router";
interface IHomeProps {
  data: HomePageQuery;
  locale: string;
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translation = await loadTranslation(
    locale,
    process.env.NODE_ENV == "production"
  );

  const {
    props: { data },
  } = await ssrHome.getServerPage(
    {
      variables: {
        languageCode: locale,
      },
    },
    null
  );

  return {
    props: { data, translation, locale },
    revalidate: 10 * 60,
  };
};

const Home: React.FunctionComponent<IHomeProps> = ({ data, locale }) => {
  const router = useRouter();
  useScrollRestoration(router, true);
  return <HomePage data={data} locale={locale} />;
};

export default Home;
