import useDateLocale from "hooks/useDateLocale";
import {
  Author,
  ExploreTopicSummaryFragment,
} from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";
import { format } from "date-fns";
import Bluetick from "@public/blue-tick.svg";
import Image from "next/image";
import Label from "@components/shared/label";
import Share from "@public/comments/paper-plane-outline.png";
import ThumbsUpIcon from "@public/comments/like.png";
import { DialogName, triggerDialog } from "@components/core/dialog";

interface INewsStackHeaderProps {
  newsStack: ExploreTopicSummaryFragment;
  author?: Author;
}

const NewsStackHeader: React.FunctionComponent<INewsStackHeaderProps> = ({
  newsStack: {
    id,
    slug,
    imageUrl,
    hot,
    updated,
    exclusive,
    description,
    exploreName,
    updatedAt,
    likesCount,
    shareCount,
    author,
  },
}) => {
  const locale = useDateLocale();

  return (
    <>
      <div className="mb-2">
        {exclusive ? (
          <Label labelName="exclusive" />
        ) : updated ? (
          <Label labelName="updated" />
        ) : (
          hot && <Label labelName="hot" />
        )}
      </div>
      <div className="mb-2 font-poppins text-4xl font-bold text-text-darkest">
        {exploreName}
      </div>
      <div className="mb-2 font-poppins text-text-medium">{description}</div>
      {author && (
        <div className="mb-4 flex w-max items-center space-x-2">
          <div className="relative h-12 w-12">
            <Image
              className="rounded-full"
              src={author.imageUrl}
              alt={author.name}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="flex-column items-center">
            <p className="font-sen text-text-medium">
              <Trans>A News Stack by</Trans>
            </p>
            <p className="flex font-sen font-bold text-text-dark">
              {author.name}
              {/* eslint-disable @next/next/no-img-element */}
              {author.verified && <img className="ml-1" src={Bluetick.src} />}
            </p>
          </div>
        </div>
      )}
      <p className="mb-2 font-sen text-xs text-text-lightest">
        <Trans>Last updated at</Trans>{" "}
        {format(new Date(updatedAt), "PP", {
          locale,
        })}
      </p>
      <div className="flex flex-row space-x-4">
        <div
          onClick={() => {
            triggerDialog(DialogName.share, {
              id: id,
              imageUrl: imageUrl,
              title: exploreName,
              publisherName: description,
              headerComp: (
                <div className="mt-3 flex items-center">
                  <div className="relative h-[100px] w-[100px]">
                    <Image
                      className="rounded-lg "
                      src={imageUrl || ""}
                      alt={exploreName}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                  <div className="ml-3 flex flex-1 flex-col">
                    <div className="mb-1">
                      {exclusive ? (
                        <Label labelName="exclusive" />
                      ) : updated ? (
                        <Label labelName="updated" />
                      ) : (
                        hot && <Label labelName="hot" />
                      )}
                    </div>
                    <p className="font-poppins font-bold">{exploreName}</p>
                    {author && (
                      <div className="flex w-max items-center space-x-1">
                        <div className="flex-column items-center">
                          <p className="flex font-sen text-sm text-text-dark">
                            {author.name}
                            {/* eslint-disable @next/next/no-img-element */}
                            {author.verified && (
                              <img className="ml-1" src={Bluetick.src} />
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ),
              url: window.location.toLocaleString(),
            });
          }}
          className="flex cursor-pointer items-center space-x-1"
        >
          <Image src={Share} width={24} height={24} alt="share" />
          <span className="font-sen text-sm font-light text-text-medium">
            {shareCount}
          </span>
        </div>
        <div
          onClick={() => triggerDialog(DialogName.download)}
          className="flex cursor-pointer items-center space-x-1"
        >
          <Image src={ThumbsUpIcon} width={24} height={24} alt="thumbs-up" />
          <span className="font-sen text-sm font-light text-text-medium">
            {likesCount}
          </span>
        </div>
      </div>
    </>
  );
};

export default NewsStackHeader;
