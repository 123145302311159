import Link from "next/link";
import LandingVideoCard from "./landing-video-card";
import {
  useFeaturedVideoQuery,
  VideoSummaryFragment,
} from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "hooks/useOnScreen";

interface TrendingVideos {
  allVideos: VideoSummaryFragment[];
}

const TrendingVideosList: React.FunctionComponent<TrendingVideos> = ({
  allVideos,
}) => {
  const { locale } = useRouter();
  const { data, loading, error } = useFeaturedVideoQuery({
    variables: {
      languageCode: locale,
    },
  });
  const [ref, setRef] = useState({
    current: null,
  });
  const router = useRouter();
  const refValue = useOnScreen(ref, router)[1];

  useEffect(() => {
    // Get the div by ID
    const youtubeEmbedDiv = document.querySelector("#youtube-embed-div");

    // Set the iframe as the current value of the ref
    setRef({ current: youtubeEmbedDiv });
  }, [loading]);

  if (loading) {
    return (
      <p className="py-4 text-center font-sen text-text-lightest">
        <Trans>Loading...</Trans>
      </p>
    );
  }

  if (error) {
    console.error(error);
    return null;
  }

  const filteredVideos = allVideos.filter(
    (video) => video.id !== data.featuredVideo?.id
  );

  return (
    <>
      <div className="mb-4 font-poppins text-2xl font-bold">
        {!data.featuredVideo ? (
          <Trans>Trending Videos</Trans>
        ) : (
          <Trans>Featured Video</Trans>
        )}
      </div>

      <div className="flex-row justify-start lg:flex lg:space-x-16">
        {!data.featuredVideo ? (
          <LandingVideoCard
            showPublisher={true}
            video={filteredVideos[0]}
            key={filteredVideos[0].id}
            showLandingBig={true}
          />
        ) : (
          <div className="md:w-3/4" ref={ref} id="youtube-embed-div">
            <YoutubeEmbedVideo
              videoId={data?.featuredVideo.externalId}
              isIntersecting={refValue?.isIntersecting}
              loading={loading}
            />
          </div>
        )}
        <div className="flex flex-col divide-y divide-light-background">
          {filteredVideos.slice(1, 4).map((video) => (
            <LandingVideoCard
              showPublisher={true}
              video={video}
              key={video.id}
              showLandingSmall={true}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="flex-1 text-right font-sen text-sm text-primary2 underline">
          <Link href={`/videos`} passHref>
            <a id="more-videos">
              <Trans>More videos</Trans>
            </a>
          </Link>
        </div>
        <ArrowRightIcon className="h-4 w-4 text-primary2" />
      </div>
    </>
  );
};

function YoutubeEmbedVideo({ videoId, isIntersecting, loading }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isIntersecting && videoRef.current != null && !loading) {
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        "*"
      );
    } else {
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  }, [isIntersecting, videoRef, loading]);

  return (
    <iframe
      id="youtube-iframe"
      className="h-96 w-full rounded-lg"
      src={`https://www.youtube.com/embed/${videoId}?autoplay=0&mute=1`}
      frameBorder="0"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      ref={videoRef}
    ></iframe>
  );
}

export default TrendingVideosList;
