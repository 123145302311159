import NewsStackHeader from "@components/news-stack/news-stack-header";
import dynamic from "next/dynamic";
import {
  HomePageQuery,
  TopNewsStackListQuery,
} from "@graphql/generated/graphql";

const NewsStackPreviewCards = dynamic(
  () => import("@components/news-stack/news-stack-preview-cards")
);
const NewsStackCardsList = dynamic(
  () => import("@components/news-stack/news-stack-cards-list")
);

interface INewsStackSectionProps {
  data: HomePageQuery;
}

interface IMappingSection {
  newsStack: TopNewsStackListQuery["allCategories"][0];
}

const NewsStackSection: React.FunctionComponent<INewsStackSectionProps> = ({
  data,
}) => {
  const { topExploreCategory } = data;

  const numberOfRichStacks = topExploreCategory.filter(
    (category) => category.richContent != null
  ).length;

  return (
    <div className="space-y-8">
      {data.allCategories.length > 2 ? (
        <HeaderAndPreviewCards newsStack={topExploreCategory[0]} />
      ) : numberOfRichStacks == 2 ? (
        <div className="md:flex md:w-1/2 md:space-x-10 md:pt-8">
          {topExploreCategory.map((category) => (
            <div key={category.id}>
              <NewsStackHeader newsStack={category} />
              {/* <NewsStackPreviewCards newsStack={category} /> */}
            </div>
          ))}
        </div>
      ) : numberOfRichStacks == 1 ? (
        <>
          {topExploreCategory.map((category) =>
            category.richContent != null ? (
              <HeaderAndPreviewCards newsStack={category} key={category.id} />
            ) : (
              <HeaderAndCardsList newsStack={category} key={category.id} />
            )
          )}
        </>
      ) : (
        topExploreCategory.map((category) => (
          <HeaderAndCardsList newsStack={category} key={category.id} />
        ))
      )}
    </div>
  );
};

export const HeaderAndPreviewCards: React.FunctionComponent<
  IMappingSection
> = ({ newsStack }) => {
  return (
    <div className="md:flex md:space-x-5">
      <div className="mb-8 pt-8 md:w-2/5">
        <NewsStackHeader newsStack={newsStack} />
      </div>
      <div className="md:w-3/5">
        <NewsStackPreviewCards newsStack={newsStack} />
      </div>
    </div>
  );
};

const HeaderAndCardsList: React.FunctionComponent<IMappingSection> = ({
  newsStack,
}) => {
  return (
    <div className="pt-8">
      <NewsStackHeader newsStack={newsStack} />
      <NewsStackCardsList newsStack={newsStack} newsCount={4} />
    </div>
  );
};

export default NewsStackSection;
