import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

export const LeftArrow: React.FunctionComponent = () => {
  const { isFirstItemVisible, scrollPrev, initComplete } =
    useContext(VisibilityContext);

  return (
    <>
      <div
        className={` mr-2 items-center rounded-lg border px-2 ease-linear
      ${initComplete ? "flex" : "invisible"}
      `}
        id="left-arrow"
      >
        <Arrow
          disabled={initComplete ? isFirstItemVisible : true}
          onClick={() => {
            scrollPrev();
          }}
        >
          <ChevronLeftIcon
            className={`h-10 w-6 transition-colors ${
              isFirstItemVisible ? "text-text-lightest" : "text-primary2"
            }`}
            aria-hidden="true"
          />
        </Arrow>
      </div>
    </>
  );
};

export const RightArrow: React.FunctionComponent = () => {
  const { isLastItemVisible, scrollNext, initComplete } =
    useContext(VisibilityContext);

  return (
    <div
      className={`relative items-center rounded-lg border px-2 ${
        initComplete ? "flex" : "invisible"
      }`}
      id="right-arrow"
    >
      <Arrow
        disabled={initComplete ? isLastItemVisible : true}
        onClick={() => {
          scrollNext();
        }}
      >
        <ChevronRightIcon
          className={`h-10 w-6 transition-colors ${
            isLastItemVisible ? "text-text-lightest" : "text-primary2"
          }`}
          aria-hidden="true"
        />
      </Arrow>
    </div>
  );
};

function Arrow({
  children,
  disabled,
  onClick,
  className = "",
  style = {},
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  className?: string;
  style?: object;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} right-1 flex cursor-pointer`}
      style={style}
    >
      {children}
    </button>
  );
}
