import { NewsQuery, useNewsListQuery } from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";
import { InfiniteList, NewsList } from "@components/core/infinite-list";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { errorToastPopup } from "@lib/errorToastPopup";

const LatestNewsList: React.FunctionComponent = ({}) => {
  const { locale } = useRouter();

  const { data, loading, fetchMore, error } = useNewsListQuery({
    variables: {
      limit: 20,
      languageCodes: [locale],
      type: NewsQuery.Latest,
    },
  });

  useEffect(() => {
    if (error) {
      errorToastPopup(error);
    }
  }, [error]);

  return (
    <div className="mb-8 space-y-4">
      <p className="font-poppins text-2xl font-bold">
        <Trans>Latest</Trans>
      </p>
      <div className="divide-y divide-light-background">
        {data && !error && (
          <InfiniteList
            infiniteLoadingOption={{
              dataLength: data.allNews.length || 0,
              next: () =>
                fetchMore({
                  variables: {
                    limit: 20,
                    offset: data.allNews.length,
                    languageCodes: [locale],
                  },
                }),
              loader: (
                <p className="py-4 text-center font-sen text-text-lightest">
                  <Trans>Loading...</Trans>
                </p>
              ),
            }}
          >
            <NewsList news={data.allNews} />
          </InfiniteList>
        )}
        {(loading || error) && (
          <div className="py-64 text-center font-sen text-text-lightest">
            <Trans>Loading...</Trans>
          </div>
        )}
      </div>
    </div>
  );
};

export default LatestNewsList;
