import React from "react";
import Image from "next/image";
import WhyUseLumiStatic from "@public/subdomain/why-use-lumi/why-use-lumi-mobile.png";
import useOneLinkUrl from "hooks/useOneLinkUrl";
import dynamic from "next/dynamic";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import HomeRating from "@public/rating/home-rating.png";

const QrCodeContainer = dynamic(
  () => import("@components/core/qr-code-container")
);

interface ILandingHeroSection {
  locale: string;
  bannerPhotoView?: boolean;
}

const LandingHeroSection: React.FunctionComponent<ILandingHeroSection> = ({
  locale,
  bannerPhotoView,
}) => {
  const switchToApp = useOneLinkUrl("https://link.luminews.my/ewC9/qrdl2");
  const router = useRouter();
  const { query } = useRouter();

  const queryText = {
    a: {
      firstLine: <Trans>Your news,</Trans>,
      secondLine: (
        <>
          &nbsp;<Trans>your way</Trans>
        </>
      ),
    },
    b: {
      firstLine: <Trans>One app to</Trans>,
      secondLine: (
        <>
          <br />
          <Trans>replace them all</Trans>
        </>
      ),
    },
    default: {
      firstLine: <Trans>Malaysia's top stories</Trans>,
      secondLine: (
        <>
          <br />
          <Trans>all in one place 🔥</Trans>
        </>
      ),
    },
  };

  return bannerPhotoView ? (
    <header className="relative mb-12 md:flex md:items-center">
      <div className="absolute z-10 hidden h-full w-1/3 bg-white md:block"></div>
      <div className="absolute right-[42%] z-10 hidden h-full w-1/4 bg-gradient-to-r from-white to-transparent md:block"></div>
      <div className="hidden h-[650px] overflow-hidden md:block">
        <video autoPlay loop muted playsInline>
          <source src="/why_use_lumi_hero_vid.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="mb-52 md:hidden">
        <Image
          src={WhyUseLumiStatic}
          alt="Group Photo"
          priority
          layout="responsive"
        />
      </div>
      <div className="absolute inset-0 top-[60%] bottom-0 bg-gradient-to-b from-white/0 to-white md:hidden"></div>
      <div className="absolute inset-x-0 -bottom-40 z-10 rounded-xl px-4 md:top-1/4 md:ml-8 md:w-1/2 md:px-0 lg:ml-24">
        <div className="text-center md:mr-8 md:text-left">
          <p className="font-poppins text-[31px] font-bold leading-[1.3] lg:text-[39px]">
            <Trans>Malaysia's top stories </Trans>
            <br />
            <span className="text-primary">
              <Trans>all in one place 🔥</Trans>
            </span>
          </p>
          <p className="mt-2 mb-4 font-sen text-xl text-text-medium">
            <Trans>An easier, faster way to read news and share it</Trans>
          </p>
        </div>
        <div className="mt-6 mb-2 hidden w-80 md:block">
          <QrCodeContainer
            size="medium"
            initialUrl="https://link.luminews.my/ewC9/qrdl2"
            text={<Trans>Scan to download the Lumi News app</Trans>}
          />
        </div>
        <div className="my-3 md:hidden">
          <a href={switchToApp}>
            <a className="flex justify-center rounded-2xl bg-primary py-3 font-sen text-white">
              <Trans>Free Download 🎉</Trans>
            </a>
          </a>
        </div>
        <p className="py-1 px-2 text-center font-sen text-xs text-text-medium md:text-left">
          <Trans>
            🥇 #1 app on Google Play Store Top Charts for News & Magazines, July
            2022
          </Trans>
        </p>
      </div>
    </header>
  ) : (
    <div className="my-auto md:flex md:flex-row">
      <div className="z-10 px-6 pt-10 md:flex md:h-[550px] md:w-6/12 md:flex-col md:justify-center md:pt-0">
        <div className="w-full text-center md:text-left">
          <div className="flex justify-center space-x-2 md:justify-start">
            <Image src={HomeRating} alt="rating" height={20} width={200} />
          </div>
          <p className="mt-2 font-poppins text-[31px] font-bold leading-[1.3] md:text-[39px]">
            {router.isReady &&
              queryText[(query.type ? query.type : "default") as string][
                "firstLine"
              ]}
            <span className="text-primary">
              {router.isReady &&
                queryText[(query.type ? query.type : "default") as string][
                  "secondLine"
                ]}
            </span>
          </p>
          <p className="mt-2 font-sen text-xl text-text-medium">
            <Trans>An easier, faster way to read news and share it</Trans>
          </p>
        </div>
        <div className="my-3 md:hidden">
          <a href={switchToApp}>
            <a className="flex justify-center rounded-2xl bg-primary py-3 font-sen text-white">
              <Trans>Free Download 🎉</Trans>
            </a>
          </a>
        </div>
        <div className="my-6 hidden w-[350px] md:block">
          <QrCodeContainer
            size="medium"
            initialUrl="https://link.luminews.my/ewC9/qrdl"
            text={<Trans>Scan to download the Lumi News app</Trans>}
          />
        </div>
        <p className="py-1 px-2 text-center font-sen text-xs text-text-medium md:text-left">
          <Trans>
            🥇 #1 app on Google Play Store Top Charts for News & Magazines, July
            2022
          </Trans>
        </p>
      </div>
      <div className="mt-auto -mb-1 md:static">
        <video width="574" autoPlay playsInline loop muted>
          <source src="/web-banner-latest.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default LandingHeroSection;
