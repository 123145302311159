import { NextRouter } from "next/router";
import { useState, useEffect, MutableRefObject } from "react";

const useOnScreen = (
  ref: MutableRefObject<HTMLDivElement>,
  router?: NextRouter,
  hasData?: boolean
) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const [entry, setEntry] = useState(null);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      setEntry(entry);
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref, hasData, router]);

  return [isIntersecting, entry];
};
export default useOnScreen;
